<template>
  <v-select
    :value="value"
    :items="locales"
    :label="label"
    :outlined="outlined"
    :prepend-icon="prependIcon"
    :dense="dense"
    :menu-props="{offsetY:true}"
    :readonly="readonly"
    :rules="rules"
    return-object
    @input="change"
  >
    <template v-slot:item="data">
      <flag
        :iso="data.item.icon"
        class="mr-2"
      />
      <v-list-item-content class="pa-0">
        {{ data.item.text }}
      </v-list-item-content>
    </template>
    <template v-slot:selection="data">
      <flag
        :iso="data.item.icon"
        class="mr-2"
      />
      <v-list-item-content class="pa-0">
        {{ data.item.text }}
      </v-list-item-content>
    </template>
  </v-select>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'LanguageInput',
  props: {
    label: {
      type: String,
      required: true
    },
    dense: {
      type: Boolean,
      default: true
    },
    outlined: {
      type: Boolean,
      default: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    prependIcon: {
      type: String,
      default: () => 'mdi-translate'
    },
    value: {
      type: Object,
      default: () => {}
    },
    rules: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapGetters(['locales', 'currentTenantId', 'tenantsList'])
  },
  created () {
    if (this.newUser) {
      const tenant = this.tenantsList.find(id => this.currentTenantId)
      const attLocale = tenant.attributes.find(att => att.name === 'locale')
      this.value = this.locales.find(l => l.value === attLocale.value)
      this.change(this.value)
    }
  },
  methods: {
    change (e) {
      this.$emit('input', e)
    }
  }
}
</script>
