<template>
  <v-text-field
    v-model="inputValue"
    :label="label"
    :outlined="outlined"
    :rules="rules"
    :dense="dense"
    :readonly="readonly"
    :prepend-icon="prependIcon"
    :type="type"
    :persistent-hint="persistentHint"
    @keyup="validator"
  />
</template>

<script>
export default {
  name: 'InputTel',
  props: {
    value: { type: [String, Number], default: null },
    label: { type: String, default: 'Phone Number' },
    type: { type: String, default: 'tel' },
    rules: { type: Array, default: () => [] },
    hint: { type: String, default: null },
    dense: { type: Boolean, default: true },
    readonly: { type: Boolean, default: false },
    outlined: { type: Boolean, default: true },
    persistentHint: { type: Boolean, default: false },
    prependIcon: { type: String, default: null }
  },
  computed: {
    inputValue: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    /**
     * @param event {KeyboardEvent}
     */
    validator: function (event) {
      const MAX_PHONE_LENGTH = 15
      event.target.value = event.target.value.replace(/[^+\d]/g, '')
      event.target.value = event.target.value.slice(0, MAX_PHONE_LENGTH)
    }
  }
}
</script>
